<template>
    <section id="workingArea">
        <div class="text-center info">
            <div class="row">
                <div class="col-md-4 col-sm-12 text-white">
                    <div class="leftBlock">
                        <div class="text-left q-mx-lg" v-for="(item, index) in tokenList" :key="`list${index}`"
                                @click="changeCoin(index)">
                            <img :src="item.logo" alt="">
                            <span class="q-ml-md q-m-sm">{{item.symbol}} ({{item.name}})</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 col-sm-12 subBlock" 
                    style="color:#abc4ed">
                    <h4 class="q-my-lg text-left text-white">
                        <img loading="lazy" class="chart" v-if ="hasChartId"
                            :src="getTickerImage" style="height:30px; margin-bottom:5px" />
                        {{getTokenName}}
                    </h4>
                    <div class="q-pb-md">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="label">Total Supply</td>
                                    <td>{{getTotalSupply}}</td>
                                </tr>
                                <tr>
                                    <td class="label">Circulation</td>
                                    <td>{{getCirculatingSupply}}</td>
                                </tr>
                                <tr>
                                    <td class="label">Twitter</td>
                                    <td><a :href="getTwitterURL" target="_blank">{{getTwitter}}</a></td>
                                </tr>
                                <tr>
                                    <td class="label">Website</td>
                                    <td><a :href="getWebSite" target="_blank">{{getWebSite}}</a></td>
                                </tr>
                                <tr>
                                    <td class="label">Block Explorer</td>
                                    <td><a :href="getBlockExplorer" target="_blank">{{getBlockExplorer}}</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr style="border-top: 1px solid rgba(171, 196, 255, 0.2);">
                    <div class="q-px-sm q-pt-md text-left" 
                        style="color: rgba(171, 196, 255, 0.9);"  
                            v-html="getDescription">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import BigNumber from 'bignumber.js'
import {token_list} from '@/constants/token.js'
import axios from 'axios'
    export default {
        components: {},
        data() {
            return {
                tokenList: [],
                tokenInfo: [],
                currentIndex: 0
            }
        },
        computed: {
            getLogoImage() {
                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined)
                    return '';

                return this.$store.state.liquidity.tokenInfo[this.currentIndex].image
            },
            getTokenName() {
                return this.tokenList[this.currentIndex].symbol + "(" 
                        + this.tokenList[this.currentIndex].name + ")";
            },
            getTotalSupply() {
                let value;

                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined) {
                    if (this.tokenList[this.currentIndex].symbol == "ARSW")
                        return '511,169,794.669'
                    else if (this.tokenList[this.currentIndex].symbol == "STAR") {
                        value =  BigNumber(this.$store.state.tokenTotalSupply.STAR).decimalPlaces(3, 1).toString();
                        return Number(value).toLocaleString('en-US');
                    }
                    else
                        return '0.00'
                }

                value = this.$store.state.liquidity.tokenInfo[this.currentIndex]
                            .market_data.total_supply;
                return value.toLocaleString('en-US');
            },
            getCirculatingSupply() {
                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined) {
                    if (this.tokenList[this.currentIndex].symbol == "ARSW")
                        return '511,169,794.669'
                    else if (this.tokenList[this.currentIndex].symbol == "STAR") {
                        value =  BigNumber(this.$store.state.tokenTotalSupply.STAR).decimalPlaces(3, 1).toString();
                        return Number(value).toLocaleString('en-US');
                    }
                    else
                        return '0.00'
                }

                let value = this.$store.state.liquidity.tokenInfo[this.currentIndex]
                            .market_data.circulating_supply;
                return value.toLocaleString('en-US');
            },
            getTwitter() {
                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined) {
                    if (this.tokenList[this.currentIndex].symbol == "ARSW")
                        return "arthswap"
                    else if (this.tokenList[this.currentIndex].symbol == "STAR")
                        return 'StarSwap';
                    else
                        return ''
                }

                let name = this.$store.state.liquidity.tokenInfo[this.currentIndex].links.twitter_screen_name;
                return name;
            },
            getTwitterURL() {
                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined) {
                    if (this.tokenList[this.currentIndex].symbol == "ARSW")
                        return "https://twitter.com/arthswap"
                    else if (this.tokenList[this.currentIndex].symbol == "STAR")
                        return 'https://twitter.com/StarSwap';
                    else
                        return ''
                }

                let name = this.$store.state.liquidity.tokenInfo[this.currentIndex].links.twitter_screen_name;
                return "https://twitter.com/" + name;
            },
            getWebSite() {
                if (this.$store.state.liquidity.tokenInfo[this.currentIndex] == undefined) {
                    if (this.tokenList[this.currentIndex].symbol == "ARSW")
                        return "https://app.arthswap.org/"
                    else if (this.tokenList[this.currentIndex].symbol == "STAR")
                        return 'https://starswap.net/';
                    else
                        return ''
                }

                let url = this.$store.state.liquidity.tokenInfo[this.currentIndex].links.homepage[0];
                return url;
            },
            getBlockExplorer() {
                return "https://blockscout.com/astar/token/" + this.tokenList[this.currentIndex].address;
            },
            getDescription() {
                let localData = this.$store.state.liquidity.tokenInfo[this.currentIndex];

                return localData && localData.description && localData.description.en ? localData.description.en : '';
            },
            hasChartId() {
                if (this.tokenList[this.currentIndex].coingeckochartid != '')
                    return true;
                else
                    return false;
            },
            getTickerImage() {
                let url;
                let chartId = this.tokenList[this.currentIndex].coingeckochartid;
                if (this.currentIndex == 0)
                    url = "https://www.coingecko.com/coins/" + chartId + "/sparkline";
                else
                    url = "https://www.coingecko.com/coins/" + chartId + "/sparkline";

                return url;
            },
        },
        created() {
            this.tokenList = token_list;
        },
        methods: {
            changeCoin(index) {
                this.currentIndex = index;
            },
        }
    }
</script>

<style>
    .label_left {
        float:left;
        text-align: left;
    }

    .label_right {
        float:right;
        text-align: right;
    }
    
    .event-message {
        font-size: 18px;
        color:orange;
        margin-top:20px;
    }
    .hilight {
        color:orange;       
    }
    .blue_hilight {
        color:rgb(24, 245, 72);       
    }

    .explorer div.row, .explorer div.col {
        margin:0 !important;
        padding:0 !important;
    }

    .info div.subBlock {
        background-color: #42a1e821; 
        border-radius: 10px; 
        width:60%; 
        padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
    padding-left: 30px;
        margin:10px auto;
        min-width:650px;
    }

    .leftBlock {
        background-color: #42a1e821; 
        border-radius: 10px; 
        margin:10px auto;
        padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
    padding-left: 20px;
    }

    .leftBlock div {
        padding:15px 0;
        color:#abc4ed;
        cursor:pointer !important;
    }

    .leftBlock img {
        margin-bottom:4px;
        width:25px;
        height:25px;
    }

    .leftBlock span {
        font-weight:500;
        font-size:1rem
    }

    .info table tbody tr td {
        text-align: left;
        padding: 10px;
        font-size: 0.8rem;
    }


    .info a {
        color:#abc4ed;
    }

    .info a:hover {
        color: rgb(247, 238, 157) !important;
        text-decoration: none !important;
    }

    .info table tbody tr td.label {
        font-size: 0.8rem;
        min-width:130px;
    }

    @media(max-width: 996px) {
        .subBlock {
            width:100%; 
        }

        .label_right {
            float:left;
            text-align: left;
        }

        .info div.subBlock {
            background-color: #42a1e821; 
            border-radius: 10px; 
            padding:10px; 
            margin:20px auto;
            min-width:90%;
        }

        .info table tbody tr td {
            max-width:180px;
            word-wrap: break-word;
        }
    }

</style>
